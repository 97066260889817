/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,900&display=swap");

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body>iframe#webpack-dev-server-client-overlay {
    display: none !important;
}

.container {
    /* max-width: 1290px !important; */
}

/* annusment */
.annusment-section {
    background-color: #282828;
    padding: 6px;
}

section.annusment-section marquee {
    display: none;
}

.inner-annusment-text h1 {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #ffffff;
}

.inner-annusment-text h1 marquee {
    display: none;
}

.main-annusment-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.inner-annusment-button button {
    padding: 7px 12px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    background: #e81c2e;
    margin-left: 14px;
    border-radius: 3px;
    color: #ffffff;
    border: 0;
}

/* header */
.mian-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.form-search {
    position: relative;
    /* width: 571px; */
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 6px;
}


ul.acoount-bar li {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.form-search input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    background-color: #ffffff;
    color: #4e565c;
    outline: none;
    width: 100%;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 0px inset;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 0px inset;
    background: rgb(247, 247, 247);
    padding: 4px;
    height: 59px;
    /* background: #FFFFFF; */
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 15px;
}

.burger-btn {
    display: none;
}

.form-search button {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #e81c2e;
    -webkit-box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    color: #ffffff;
    padding: 0px 23px;
    margin-left: -4px;
    position: absolute;
    cursor: pointer;
    border-radius: 6px;
    height: 49px;
    border: none;
    outline: none;
    right: 4px;
    top: 4px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 700;
    border: 2px solid #e81c2e;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.form-search button:hover {
    color: #e81c2e;
    border: 2px solid #e81c2e;
    background-color: transparent;
}

.form-search button path {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.form-search button:hover path {
    fill: #e81c2e;
    /* transform: rotate(360deg);/ */
}

ul.acoount-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin: 0;
}

.main-header {
    padding: 15px 0px;
    border-bottom: 1px solid #dddddd;
}

.nav-link {
    color: #272727;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.nav-link:hover {
    color: #272727;
}

/* navbar */
.navigation-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    /* justify-content: space-between; */
}

.navigation-list li:hover {
    border-top: 3px solid #e81c2e;
}

.navigation-list li {
    padding: 18px 0px;
    border-top: 3px solid transparent;
}

/*.navigation-list li {
    margin-right: 40px !important;
}*/

.crose {
    padding-top: 21px;
    display: none;
}

.filter-crose {
    padding-top: 21px;
    display: none;
}

.navigation-list li a {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-transform: uppercase;
    color: #272727;
    letter-spacing: 0.035em;
    display: block;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    text-align: left;
    /* background: red; */
    width: 100% !important;
}

.navigation-list li span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-transform: uppercase;
    color: #272727;
    letter-spacing: 0.035em;
    display: block;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    text-align: left;
    /* background: red; */
    width: 100% !important;
    cursor: pointer;
}

.navigation-list li a:hover {
    color: #e81c2e;
    text-decoration: none;
}

i.fa-solid.fa-caret-down {
    margin-left: 2px;
}

/* 
    .navigation-bar {
        padding: 19px 0px;
    } */

/* bannner  */
.main-banner {
    background: #595959;
    background-image: -o-linear-gradient(359.97deg,
            #000000 -20.1%,
            rgba(0, 0, 0, 0) 100%),
        url(../public/images/banner_bg.jpg);
    background-image: linear-gradient(90.03deg,
            #000000 -20.1%,
            rgba(0, 0, 0, 0) 100%),
        url(../public/images/banner_bg.jpg);
    height: 610px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-position: center right;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.banner-under-data h2 {
    font-family: Roboto;
    font-size: 50px;
    font-weight: 800;
    max-width: 550px;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 40px;
    color: #ffffff;
}

.banner-under-data p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-bottom: 35px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #ffffff;
    background-color: #0d6efd;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    background: #e81c2e;
    border-radius: 8px;
}

.nav-pills .nav-link {
    background: #fff;
    border-radius: 10px 10px 0px 0px !important;
    margin-right: 5px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 13px 17px;
    color: #8e8e8e;
}

.tab-content>.active {
    display: block;
    background: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px;
    background: #ffffff;
    -webkit-box-shadow: 4px -4px 15px rgba(21, 51, 129, 0.1);
    box-shadow: 4px -4px 15px rgba(21, 51, 129, 0.1);
    border-radius: 0px 8px 8px 8px;
}

.serch-btn {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #e81c2e;
    -webkit-box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    color: #ffffff;
    padding: 0px 23px;
    cursor: pointer;
    border-radius: 6px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    height: 56px;
    border: none;
    outline: none;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    border: 2px solid;
    text-align: left;
    color: #ffffff;
}

.serch-btn:hover {
    color: #e81c2e;
    border: 2px solid #e81c2e;
    background-color: transparent;
}

.serch-btn path {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.serch-btn:hover path {
    fill: #e81c2e;
    /* transform: rotate(360deg); */
}

.selet-btns {
    background: #ffffff;
    border-radius: 4px;
    height: 56px;
    width: 150px;
    border: 1px solid rgba(36, 39, 44, .3);
    font-family: Roboto;
    font-size: 16px;
    margin-right: 8px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
    outline: none;
    padding: 14px;
}

.bottom-data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 25px;
}

.bottom-data p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: 0em;
    border-right: 2px solid #fff;
    margin-right: 20px;
    text-align: left;
}

.bottom-data span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-right: 20px;
}

/* Categories section */

.categrories-txt {
    font-family: Roboto;
    font-size: 40px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    color: #272727;
    padding-top: 64px;
    padding-bottom: 15px;
}

.catigrious-name {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 14px;
    color: #272727;
}

.catigioa-2 {
    padding-top: 38px;
}

.categrories img {
    width: 198px;
    height: 198px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.categrories {
    /* overflow: hidden; */
}

.categrories:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.view-catagirs-btn button {
    padding: 18px 26px;
    color: #e81c2e;
    font-family: Roboto;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    background: #ffffff !important;
    margin-top: 40px;
    border: 2px solid #e81c2e;
    border-radius: 4px;
}

.view-catagirs-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 60px;
}

/* Popular Products */
.mian-popular-banner {
    height: auto;
    background-image: url(../public/images/fast-car.png);
    background-repeat: no-repeat;
    background-position: center;
}

.selling-product {
    background: #ffffff;
    border-radius: 10px;
    padding: 12px 27px 5px 21px;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}

.selling-product:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
}

.product-data h6 {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 11px;
    color: #000000;
}

.product-data h4 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
}

.product-data img {
    -webkit-transition: 0.5s !important;
    -o-transition: 0.5s !important;
    transition: 0.5s !important;
}

.product-inner-image {
    overflow: hidden;
}

.product-data:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.product-data:hover .product-name {
    text-decoration: underline !important;
}

.selling-product h3 {
    font-family: Roboto;
    font-size: 23px;
    font-weight: 800;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
}

.product-iteam {
    padding-top: 70px;
}

/* tires section  */
.main-tires-section {
    background-color: #ffffff;
}

.tyre-section-txt {
    font-family: Roboto;
    font-size: 40px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    color: #272727;
    padding-top: 64px;
}

.card-tyr {
    background: #ffffff;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border-radius: 8px;
}

.card-tyr:hover {
    -webkit-box-shadow: 0px 0px 10px 4px #ccc;
    box-shadow: 0px 0px 10px 4px #ccc;
}

.price-cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 14px;
}

.cart-body {
    padding: 17px 14px 10px 14px;
}

.cart-body .similar_pro_cat_name {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 0;
    margin-bottom: 0.5rem;
    display: block;
}

.cart-body h4 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #E81C2E;
}

.card-tyr:hover .cart-body h4 {
    text-decoration: underline;
    cursor: pointer;
}

.tyre-imge-upper {
    overflow: hidden;
}

.image-product-tyre {
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}

.card-tyr:hover .image-product-tyre {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.price h5 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #e81c2e;
}

.price p {
    font-family: Roboto;
    display: none;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #595959;
    padding-top: 4px;
}

.price span {
    font-size: 11px;
}

.vieww-all {
    padding: 18px 47px !important;
    background-color: #f2f2f2 !important;
}

.view-catagirs-btn button:hover {
    background: #e81c2e !important;
    color: #fff;
}

/* search section  */
.mian-serch-section {
    background-image: url(../public/images/serch.png);
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.under-data-srech h2 {
    font-family: Roboto;
    font-size: 40px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    margin: 0;
    text-align: center;
    color: #ffffff;
}

.under-data-srech h4 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
}

.under-data-srech {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

/* parts section  */
.blue-car {
    background-image: url(../public/images/blue-car.png);
    height: 270px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 10px 0px;
    padding: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
}

.indoor-car {
    background-image: url(../public/images/under-car.png);
    height: 270px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 10px 0px;
    padding: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
}

.tools-car {
    background-image: url(../public/images/tool.png);
    height: 550px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 10px 0px;
    padding: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.car-wheels {
    background-image: url(../public/images/wheels-car.png);
    height: 270px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 10px 0px;
    padding: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
}

.body-parts {
    background-image: url(../public/images/body-part.png);
    height: 270px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 10px 0px;
    padding: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
}

.enter-main-banner {
    padding: 72px 0px;
}

.txt-degine-grid h2 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.txt-degine-grid a {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.main-outer-bgs {
    overflow: hidden;
}

.txt-degine-grid {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.txt-degine-grid:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.txt-degine-grid:hover a {
    text-decoration: underline;
}

/* top brands section  */
.main-top-brands {
    background: #f2f2f2;
    padding-bottom: 80px;
    padding-top: 30px;
}

.top-brands {
    overflow: hidden;
}

.top-brands img:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.col-lg-2.top-brands img {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}

.second-logo {
    margin-top: 20px;
}

/* news letter section  */
.main-news-letter {
    background-image: url(../public/images/bg-1.png);
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.news-letter h2 {
    font-family: Roboto;
    font-size: 40px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.news-letter p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.news-letter {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.news-letter-serech {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.providing .service_heading {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 22px;
    color: #595959;
    margin-bottom: .5rem;
    display: block;
}

.providing h2 {
    font-family: Roboto;
    font-size: 25px;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: #272727;
}

.providing {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}

.main-shiiping-setion {
    padding-top: 80px;
    padding-bottom: 80px;
}

/* footer section  */
.fottor-list li {
    list-style: none;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.fottor-list li a:hover {
    color: #e81c2e;
    text-decoration: underline;
}

.fottor-list li a {
    font-family: Roboto;
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #595959;
}

.fottor-data h2 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
}

.mian-footer-section {
    padding: 65px 0px;
    border-bottom: 1px solid #dddddd;
}

.fottor-data .menus_heads {
    font-family: Roboto;
    font-size: 16px;
    color: #272727;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
}

.fottor-data p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    max-width: 250px;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #595959;
    text-align: left;
}

.social-icons i:hover {
    color: #e81c2e;
}

.social-icons svg path:hover {
    fill: #e81c2e;
}

/* copy right section  */
.main-copy-right {
    height: 62px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.copy-right {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #595959;
}

/* mega menu section  */
.main-menu-1 {
    position: relative;
}

.mega-menu {
    position: absolute;
    background: #fff;
    padding: 15px 31px;
    width: 330px;
    top: 60px;
    z-index: 9;
    border-top: 3px solid #e81c2e;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    top: 100%;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

li.main-menu-1:hover .mega-menu {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    top: 100%;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}

.menu-iteam h2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #e81c2e;
    margin: 0;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 9px;
}

.menu-list-mega li {
    list-style: none;
    padding: 0;
    border: 0 !important;
}

.menu-list-mega li a {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-transform: capitalize;
    text-align: left;
    color: #757575;
}

.mega-menu::after {
    content: "";
    position: absolute;
    top: -11px;
    left: 40px;
    margin-left: -5px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    border-width: 5px;
    border-style: solid;
    border-color: #e81c2e transparent transparent transparent;
}

.menu-list-mega li:hover {
    border: none;
}

.menu-list-mega li {
    border: none;
}

.ligt-main {
    position: relative;
}

.light-mega {
    position: absolute;
    background: #fff;
    padding: 15px 31px;
    width: 330px;
    z-index: 9;
    top: 60px;
    border-top: 3px solid #e81c2e;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    top: 100%;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

.light-mega li:hover {
    border: none;
}

.light-mega li {
    border: none;
}

.light-mega::after {
    content: "";
    position: absolute;
    top: -11px;
    left: 40px;
    margin-left: -5px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    border-width: 5px;
    border-style: solid;
    border-color: #e81c2e transparent transparent transparent;
}

li.ligt-main:hover .light-mega {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    top: 100%;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}

.wheel-mega-main {
    position: relative;
}

.wheel-mega-menu {
    position: absolute;
    z-index: 9;
    background: #fff;
    padding: 15px 31px;
    width: 330px;
    top: 60px;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    top: 100%;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    border-top: 3px solid #e81c2e;
}

.wheel-mega-menuli:hover {
    border: none;
}

.wheel-mega-menu li {
    border: none;
}

.wheel-mega-menu::after {
    content: "";
    position: absolute;
    top: -11px;
    left: 40px;
    margin-left: -5px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    border-width: 5px;
    border-style: solid;
    border-color: #e81c2e transparent transparent transparent;
}

li.wheel-mega-main:hover .wheel-mega-menu {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    top: 100%;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}

::-webkit-input-placeholder {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #939393;
    text-align: left;
}

::-moz-placeholder {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #939393;
    text-align: left;
}

:-ms-input-placeholder {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #939393;
    text-align: left;
}

::-ms-input-placeholder {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #939393;
    text-align: left;
}

::placeholder {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #939393;
    text-align: left;
}

/* cheat bot  */
.chat-bot {
    height: 52px;
    position: absolute;
    width: 147px;
    right: 17px;
    bottom: 10px;
    background: #e81c2e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 50px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-shadow: 6px 6px 20px 0px #e81c2e33;
    box-shadow: 6px 6px 20px 0px #e81c2e33;
}

.live-chat {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}





.nav-pills {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: auto;
}

i.fa-solid.fa-car-side {
    display: none;
    margin-right: 5px;
}

.nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

.product-deastil-preice {
    border-top: 1px solid #dddddd;
}

.lenghtWidhtHightSection {
    border-bottom: 1px solid #dddddd;
}

.lenghtWidhtHightSection img {
    width: 20px !important;
}


/* //new css add */
.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.cart-btn a img {
    max-width: 100%;
    width: 79px;
    height: 79px;
}

.engle-imge-upper img {
    height: 163px;
    min-height: 163px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    object-fit: contain;
}

.Drivetrain-cart .cart-body {
    padding: 17px 14px 10px 14px;
    height: auto;
}

.Drivetrain-cart .cart-body {
    overflow: hidden;
}

.price-cart {
    padding-top: 0px !important;
}

.cart-body .description {
    line-height: 1.2;
    height: auto;
    /* Set the height for three lines */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Limit the text to three lines */
    -webkit-box-orient: vertical;
}


.main-banner .loader {
    border: 4px solid #f3f3f3;
    /* Light gray */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    position: absolute;
    top: 30%;
    left: 7%;
}

.main-banner .loaderMake {
    border: 4px solid #f3f3f3;
    /* Light gray */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    position: absolute;
    top: 30%;
    left: 29%;
}

.main-banner .loaderModel {
    border: 4px solid #f3f3f3;
    /* Light gray */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    position: absolute;
    top: 30%;
    left: 49%;
}


.main-banner .loaderVarient {
    border: 4px solid #f3f3f3;
    /* Light gray */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    position: absolute;
    top: 30%;
    left: 69%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



i.fa.fa-angle-up,
i.fa.fa-angle-down {
    color: black;
}

.loading:after {
    content: " .";
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: white;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
}


.slider {
    padding: 0px 33px 30px 0px;
    color: #fff;
    position: sticky;
    top: 0;
}

.main-product-data {
    padding-left: 10px;
}

.swiper-slide.swiper-slide-visible:hover {
    border: 2px solid #E81C2E;
    border-color: #E81C2E !important;
}

/*.brandPageTile {
    background-color: white;
    margin: 5px 20px;
    height: 110px !important;
    padding: 10px;
    display: flex;
    justify-content: center;
}*/

/* .brandPageTile img{
    width: 50%;
} */
/* media quary  */

.popupImagesDiv {
    padding: 10px 20px;
}

.popupImagesDiv img {
    height: 101px;
    margin-bottom: 5px;

}

.popupImagesDiv label {
    text-align: center;
}

.tyre-imge-upper {
    height: 160px;
}

.tyre-imge-upper img {
    width: 100%;
}

.customeChanges {
    width: 20% !important;
    margin: 0 !important;
}

.wheelsPage .engle-imge-upper img {
    height: 208px !important;
}

.singleWheelSpecification {
    border-top: 1px solid #DDDDDD;
}

.mainAttributes {
    border-top: 1px solid #DDDDDD;
    padding: 5px 0;
}

.mainAttributes .row {

    padding: 5px 0;
}

.wheelDetailsContainer {
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 10px;
}

.singleWheelSpecification table td {
    padding: 8px 2px;
}

.wheelsPage .price-cart img {
    max-width: 70px;
    max-height: 26px !important;
}

.iconImage {
    margin-right: 6px;
}

.producDescription {
    padding: 10px 0;
}

.label-filter {

    line-height: 20px !important;
    cursor: pointer;

}

.hover:hover {
    background-color: #dddddd;
}

.wheelsBrandList {
    height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    background-color: #dddddd;
}

.list-filter {}

.list-filter::-webkit-scrollbar {
    display: none;
}

.wheelsBrandList::-webkit-scrollbar {
    width: 10px;
    /* Adjust the width of the scrollbar */
}

.diameterDiv li {
    text-decoration: none;
    display: block;
    display: inline-block;
    padding: 10px 20px;
    background: aliceblue;
    margin: 10px;
    font-size: 39px;
    font-weight: 800;
    cursor: pointer;
}

.categoryModel .modal-body {
    max-height: 80vh;
    overflow: scroll;
    overflow-x: hidden;

}

.popupImagesDiv img {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.popupImagesDiv img:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.popupImagesDiv .catigrious-name {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 14px;
    color: #272727;
}

.popupImagesDiv .categrories img {
    width: 134px !important;
    height: 134px !important;
    transition: 0.5s;
}

.mt-10 {
    margin-top: 20px;
}

.categrories {
    cursor: pointer;
}

.mainDivForMake {
    position: relative;
}

.ulForMake {
    /* display: none; */
    position: absolute;
    text-decoration: none;
    background-color: #fff;
    height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    left: 160px;
    z-index: 1;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0;

}

.ulForMake li {
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    padding: 5px;
}

.ulForMake li:hover {
    background-color: blue;
    color: white;
}


.ulForModel {
    /* display: none; */
    position: absolute;
    text-decoration: none;
    background-color: #fff;
    height: 200px;
    overflow: scroll;
    overflow-x: hidden;
    left: 330px;
    z-index: 1;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0;
    width: 148px;

}

.ulForModel li {
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    padding: 5px;
}

.ulForModel li:hover {
    background-color: blue;
    color: white;
}


.ulForVarient {
    /* display: none; */
    position: absolute;
    text-decoration: none;
    background-color: #fff;
    height: 200px;
    overflow: scroll;
    overflow-x: hidden;
    left: 476px;
    z-index: 1;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0;
    width: 148px;

}

.ulForVarient li {
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    padding: 5px;
}

.ulForVarient li:hover {
    background-color: blue;
    color: white;
}

.selectMainDiv {
    margin: 5px;
    width: 154px;
}

.css-1u9des2-indicatorSeparator {
    display: none !important;
}

.selectMainDiv input {
    line-height: 2.3 !important;
}

.loader-line {
    width: 100%;
    height: 3px;
    position: fixed;
    overflow: hidden;
    background-color: #ddd;
    margin: 0;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    z-index: 1;
}

.loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: red;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }

    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}

.hide {
    display: none;
}

.cursor_pointer {
    cursor: pointer;
}

.container.mt-5 .card-body {
    flex: 1 1 auto;
    padding: 10px !important;
}

.mt-10 .card {
    margin-top: 10px !important;
}

.selectMainDivEditPopup {
    margin: 10px 0;
    position: relative;
}

.selectMainDivEditPopup .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    position: absolute;
    top: 53%;
    left: 43%;

}

.selectMainDivEditPopup .loaderMake {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    position: absolute;
    top: 53%;
    left: 43%;

}


.selectMainDivEditPopup .loaderModel {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    position: absolute;
    top: 53%;
    left: 43%;

}

.selectMainDivEditPopup .loaderVarient {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    position: absolute;
    top: 53%;
    left: 43%;

}

.modal-content {

    border-radius: 10px !important;

}

.secrch-bar {
    width: 55%;
}

.alertForClear {
    position: absolute !important;
    z-index: 1;
    width: 20%;
    margin: auto;
    height: 24px;
    top: 40%;
    left: 40%;
}

.wheelattributeFilter {
    height: 300px;
    overflow: scroll;
    overflow-x: hidden;
}

/*New css 28-6-23*/
span.span-filter-Checkbox.m-1 {
    padding: 2px;
    border: 1px solid red;
    border-radius: 5px;
    background: #FFEDEF;
}

.selectedFilters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5px;
}

.preloader {
    align-items: center;
    background: rgb(23, 22, 22);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s linear;
    width: 100%;
    z-index: 9999;
    opacity: 0.6;
}

.selectedFilters span.span-filter-Checkbox.m-1 {
    background: #E81C2E;
    color: #fff;
    border-radius: 30px;
    padding: 2px 15px 3px;
    font-size: 15px;
}

.selectedFilters span.span-filter-Checkbox.m-1 i {
    position: relative;
    width: 10px;
    height: 10px;
}

.selectedFilters span.span-filter-Checkbox.m-1 i::before {
    position: absolute;
    left: 6px;
    content: ' ';
    height: 13px;
    bottom: -2px;
    width: 2px;
    background-color: #fff;
    transform: rotate(45deg);
}

.selectedFilters span.span-filter-Checkbox.m-1 i::after {
    position: absolute;
    left: 6px;
    content: ' ';
    height: 13px;
    width: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
    bottom: -2px;
}

.selectedFilters .clearFilterStyle {
    padding-left: 8px;
    font-size: 16px;
    color: #e81c2e;
    font-weight: 500;
    font-family: Roboto;
    cursor: pointer;
}

.bottom-data p a {
    color: white;
}

.bottom-data p a:hover {
    border-bottom: 1px solid white;
    cursor: pointer;
    color: white;
}


.tgl {
    display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl+.tgl-btn {
    box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl+.tgl-btn::-moz-selection {
    background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl+.tgl-btn::selection {
    background: none;
}

.tgl+.tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tgl+.tgl-btn:after,
.tgl+.tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl+.tgl-btn:after {
    left: 0;
}

.tgl+.tgl-btn:before {
    display: none;
}

.tgl:checked+.tgl-btn:after {
    left: 50%;
}

.tgl-light+.tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
}

.tgl-light+.tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
}

.tgl-light:checked+.tgl-btn {
    background: #E81C2E;
}


.wheelsPage.leftSide {
    background-color: #e8e8e8;
    height: 100%;
}

.wheelsPage p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: roboto;
}

.row.Drivetrain-cart.wheelsPageDiv {
    margin: 0 !important;
    /* border: none !important; */
    padding-bottom: 0;
}

.row.Drivetrain-cart.wheelsPageDiv .col-lg-6.col-6 {
    padding: 0;
}

.wheelsPage.rightSide {
    padding: 10px 15px 10px;
    height: 100%;
    cursor: pointer;
}



.staggeredLi {
    margin-left: 12px;
    font-weight: 600;
    font-size: 20px;
}

.cart-box .shimmer-badge {

    margin-bottom: 0 !important;
}

/* media secreen start */


@media screen and (max-width: 767px) {
    .secrch-bar {
        display: none;
    }

    .main-header .secrch-bar .search_box_main form.form-search input {
        margin-left: 13px;
    }

    .account-section {
        display: none;
    }

    /*.mega-menu.open {
        -webkit-transform: rotateX(0deg) !important;
        transform: rotateX(0deg) !important;
    }

    .mega-menu {
        -webkit-transform: rotateX(90deg) !important;
        transform: rotateX(90deg) !important;
    }*/

    /*.light-mega.open {
        -webkit-transform: rotateX(0deg) !important;
        transform: rotateX(0deg) !important;
    }

    .light-mega {
        -webkit-transform: rotateX(90deg) !important;
        transform: rotateX(90deg) !important;
    }*/

    /*.wheel-mega-menu.open {
        -webkit-transform: rotateX(0deg) !important;
        transform: rotateX(0deg) !important;
    }

    .wheel-mega-menu {
        -webkit-transform: rotateX(90deg) !important;
        transform: rotateX(90deg) !important;
    }*/

    .logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: start;
        width: 55%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

    .banner-under-data h2 {
        padding-top: 0px;
        font-size: 27px !important;
        line-height: 34px;
        max-width: 300px;
        font-weight: 600;
    }

    /*.banner-under-data p {
        display: none;
    }*/

    .main-banner {
        background: #757575;
        height: auto;
        padding: 30px 0 20px;
        background-position: center;
        background-image: linear-gradient(90.03deg,
                #000000 -25.1%,
                rgba(0, 0, 0, 0) 50.91%),
            url(../public/images/bg_mobile_banner.jpg);
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        font-size: 14px;
    }

    .nav-pills .nav-link {
        font-size: 14px;
        padding: 15px 13px;
    }

    .selet-btns {
        margin-top: 10px;
    }

    .serch-btn {
        width: 100%;
        margin-top: 8px;
        text-align: center;
    }

    .selet-btns {
        width: 100%;
    }

    /*.bottom-data {
        display: none;
    }*/

    .burger-btn {
        display: block;
    }

    .mian-popular-banner {
        height: auto !important;
        background-repeat: no-repeat;
        background-position: top;
        padding-bottom: 30px;
        background-size: cover;
    }

    .selling-product h3 {
        font-size: 18px;
    }

    .product-data h4 {
        font-size: 14px;
    }

    .selling-product {
        margin-top: 14px;
    }

    .tyre-section-txt {
        padding-top: 26px;
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 12px;
    }

    .cart-body h4 {
        font-size: 14px;
        line-height: 14px;
    }

    .card-tyr {
        margin-top: 20px;
    }

    .top-brands img {
        width: 100%;
        margin-top: 10px
    }

    .cart-btn img {
        width: 100%;
    }

    .catigioa-2 {
        padding: 0;
    }

    .categrories-txt {
        font-size: 28px;
        font-weight: 800;
        line-height: 40px;
        padding-top: 24px;
    }

    .product-iteam {
        padding-top: 30px;
    }

    ul.navigation-list {
        display: block;
    }

    .navigation-list li {
        margin-right: 0px !important;
        border-bottom: 1px solid #ccc;
    }


    i.fa-solid.fa-caret-down {
        float: right;
    }

    .news-letter-barr {
        display: block;
        margin-top: 0 !important;
    }

    .news-letter h2 {
        font-size: 26px;
        line-height: 30px;
    }

    .news-letter p {
        font-size: 14px;
        line-height: 18px;

    }

    .main-news-letter {
        height: auto;
        padding: 30px 0;
    }

    .serch-bar-2 {
        display: block;
    }

    nav.navigation-bar {
        /* display: none; */
        position: fixed;
        overflow: scroll !important;
        top: 0;
        background: #ffff;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        width: 100%;
        height: 100%;
        z-index: 999;
        left: 100%;

    }

    nav.navigation-bar.open {

        left: 0%;

    }


    .price h5 {
        font-size: 17px;
        line-height: 14px;
    }

    .mian-footer-section {
        padding: 0px 0px;
    }

    .form-search {
        /* max-width: 571px !important; */
        width: 100%;
    }

    .main-shiiping-setion {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .providing {
        margin-top: 13px;
    }

    .providing h2 {
        font-size: 18px;
        line-height: 21px;
    }

    .providing .service_heading {
        font-size: 13px;
        line-height: 15px;
    }

    .second-logo {
        margin-top: 0px;
    }

    i.fa-solid.fa-car-side {
        display: block;
    }

    .btn-text-uppar {
        display: none;
    }

    .nav-pills .nav-link.active .btn-text-uppar {
        display: block;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {

        line-height: 14px;

    }

    .inner-annusment-text h1 marquee {
        display: block;
    }

    .inner-annusment-text h1 span {
        display: none !important;
    }

    .crose {
        padding-top: 15px;
        padding-bottom: 15px;
        display: block;
        text-align: end;
    }

    .navigation-bar .crose button img {
        width: 15px;
    }

    .filter-crose {
        padding-bottom: 8px;
        display: block;
        text-align: end;
        padding-top: 0;
        padding-right: 10px;
    }

    .enter-main-banner {
        display: none;
    }

    .main-copy-right {
        height: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 20px 0px;
        text-align: center;
    }

    .copy-right {
        margin: 0;
        text-align: center;
    }

    .social-icons {
        padding: 20px 0px;
    }


    section.annusment-section marquee {
        display: block;
    }

    .mobile-mar {
        display: none;
    }

    .fottor-data h2 {
        margin-top: 25px;
    }

    /*.wheel-mega-menu {
        max-width: 500px;
    }*/

    /*.mega-menu {
        transition: none !important;
        top: 20px !important;
        position: relative;
        transform: none !important;
        display: none;
        width: 100% !important;
    }

    .light-mega {
        transition: none !important;
        top: 20px !important;
        position: relative;
        transform: none !important;
        display: none;
        width: 100% !important;
    }*/

    /*.wheel-mega-menu {
        transition: none !important;
        top: 20px !important;
        position: relative;
        transform: none !important;
        display: none;
        width: 100% !important;
    }*/

    .main-outer-filter {
        /* display: none; */
        position: fixed;
        top: 0;
        background: #fff;
        height: 100%;
        z-index: 9999;
        left: 100%;
        overflow-y: scroll;
        padding: 15px;
        transition: .4s;
        padding-bottom: 55px;
        width: 100%;
    }

    .main-filter-resposive {
        display: block !important;
    }

    .Drivetrain {
        display: block !important;
    }

    .Drivetrain p {
        padding-top: 10px;
        text-align: left !important;
    }

    .product-name-details .tag_name {
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 20px !important;
    }

    .product-name-details h2 {
        font-size: 25px !important;
        line-height: 30px !important;
    }

    .product-deastil-preice h2 {
        font-size: 25px !important;
        line-height: 40px !important;
    }

    .product-deastil-preice h3 {
        font-size: 14px !important;
        line-height: 20px !important;

    }

    .slider__prev {
        position: absolute !important;
        top: 9px !important;
        left: -14px !important;
        padding: 10px 10px 10px 12px !important;
        width: 27px !important;
        transform: matrix(0, 1, 1, 0, 0, 0) !important;
        height: 27px !important;
    }

    .slider__next {
        position: absolute !important;
        top: 9px !important;
        left: 0;
        right: -14px !important;
        left: 92% !important;
        padding: 10px 10px 10px 12px !important;
        width: 27px !important;
        transform: matrix(0, 1, 1, 0, 0, 0) !important;
        height: 27px !important;
    }
}

.main.contactusForm {
    width: 40%;
    margin: auto;
}

.position-relative {
    margin-bottom: 14px;
}

.form-group.message {
    margin-bottom: 14px;
}

.form-group.messageBtn {
    width: 191px;
    height: 78px;
    margin-left: 163px;
    margin-bottom: 14px;
}

.contact_form .main>p {
    margin-bottom: -15px;
}

.recaptcha-container {
    margin-left: 108px;
    margin-bottom: 14px;
}

.brandNotFound {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
}



/* mobile  */
@media screen and (max-width:576px) {
    .categrories img {
        width: 100%;
        height: auto !important;
    }

    /* .parformane-bar {
        margin-top: -14px;
    }
    .main-banner {
        margin-top: -14px;
    } */
    .position-relative {
        margin-bottom: 0px;
    }

    .row.forgotPassword {
        width: 100% !important;
    }

    .row.resetPassword {
        width: 100% !important;
    }

    .bottom-data .brandShop {
        border-right: none;
    }

    .recaptcha-container {
        margin-left: 33px;
        margin-bottom: 14px;
    }

    .main.contactusForm {
        width: 100%;
        margin: 0;
    }

    .form-group.messageBtn {
        margin-left: 87px;
    }

    .contact_form {
        margin-top: -30px;
    }

    .search_opener.active {
        left: 0;
        overflow: scroll;
        background: #fff;
    }

    .bottom-data span {
        margin-left: 39px;
    }

    .brand-filters.row {
        max-height: 800px;
        overflow-y: scroll;
    }

    .brand-filters.row {
        padding-right: 5px;
    }

    /* .brand-filters.row::-webkit-scrollbar {
        height: 5px;
    } */


    .catigrious-name {
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        text-align: center;

    }

    .main-header .secrch-bar .search_box_main form.form-search input {
        margin-left: 13px;
    }

    .view-catagirs-btn button {
        margin-top: 20px;
    }

    .view-catagirs-btn {
        padding-bottom: 30px;
    }

    .product-data img {
        width: 100%;
    }

    .mega-menu {
        width: 100%;
    }

    .light-mega {
        width: 100%;
    }

    .wheel-mega-menu {
        width: 100%;
    }

    /*.quantity {
        display: block !important;
    }*/

    .incres {
        width: 133px !important;
    }

    .Finance button {
        margin-left: 0px !important;
        margin-top: 14px;
    }

    .cart button {
        margin-top: 14px;
    }
}




/* listting page  */

.main-inner-banner {
    background-image: url(../public/images/main-listing-banner.png);
    height: 230px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.warrent-banner {
    background-image: url(../public/images/warnty-banner.png);
    height: 230px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.term-banner {
    background-image: url(../public/images/term-banner-2.png);
    height: 270px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.parformane-bar {
    height: 48px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #00000081;
}

.listing-parmofamne li {
    list-style: none;
    display: inline;
}

.listing-parmofamne li a {
    list-style: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #B1B1B1;
}

li.active-bread a {
    color: #fff;
}

.txt-main-listing {
    font-family: Roboto;
    font-size: 45px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding-top: 40px;
}

.Drivetrain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    width: 100%;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.main-section-lsting {
    padding-top: 30px;
}

.filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.filters h3 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
}

.Drivetrain h3 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    margin: 0;
    text-align: left;
    color: #272727;
}

.Drivetrain p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: right;
    color: #272727;
}

.Drivetrain-cart {
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 6.63619px;
    margin-bottom: 0;
    height: 100%;
    position: relative;
    /* padding-bottom: 55px; */
}

/* .Drivetrain-cart .cart-body .price-cart:last-child {
    position: absolute;
    left: 14px;
    bottom: 1px;
    right: 14px;
} */

.crose button {
    border: none;
    background: transparent;
}

.filter-crose button {
    border: none;
    background: transparent;
    line-height: 24px;
}

.burger-btn button {
    background: transparent;
    border: 0;
}

.price-engle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price-engle h5 {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 800;
    line-height: 14px;
    color: #E81C2E;
    letter-spacing: 0em;
    text-align: left;
}

.compare-price {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #595959;
    margin-left: 8px !important;
}

.saving {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: center;
    color: #E81C2E;
    background: #FFEEEF;
    border: 0.744914px solid #E81C2E;
    border-radius: 12px;
    padding: 5px 10px;
    margin-left: 12px;
}

.month-logo {
    display: flex;
    align-items: center;
    padding-top: 6px;
}

.month-logo p {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    display: none;
    letter-spacing: 0em;
    text-align: left;
    color: #595959;
}

.month-logo p span {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #595959;
    letter-spacing: 0em;
    text-align: left;
}

.select-bars {
    background: #F2F2F2;
    padding: 15px;
    border-radius: 4px;
    margin-top: 12px;
}

.clear-filters {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #E81C2E;
    border: 2px solid #E81C2E;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    background-color: #f5f5f5;
    margin-top: 20px;
    transition: 0.3s;
}

.clear-filters:hover {
    color: #ffffff;
    background-color: #E81C2E;
}

.price-filter-text {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: #272727;
    letter-spacing: 0em;
    text-align: left;
    justify-content: space-between;
    margin-bottom: 14px;
}

.price-filter {
    background: #FFFFFF;
    border-bottom: 1px solid #E8E8E8;
    border-radius: 4px;
    padding: 15px 20px 15px 0;
    margin-bottom: 15px;
}

.price-filter .collapse ul li {
    padding-bottom: 10px;
}

.main-outer-filter .aside-price-range-form {
    margin-top: 0 !important;
    margin-bottom: 46px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.main-outer-filter .rs-slider .rs-slider-progress-bar {
    background-color: #E81C2E;
}

.main-outer-filter .price-filter .rs-slider {
    margin: 0 15px;
}

.main-outer-filter .rs-slider .rs-slider-handle::before {
    background: #E81C2E;
    border: 2px solid #E81C2E;
    width: 16px;
    height: 16px;
    cursor: grab;
}

.main-outer-filter .rs-slider .rs-slider-handle:focus::before {
    cursor: grabbing;
}

.main-outer-filter .rs-slider .rs-slider-handle {
    transform: translateY(-2px);
}




.list-filter li {
    list-style: none;

}

.filter-box {
    height: 18px;
    width: 18px;
    accent-color: #E81C2E;
    margin-right: 10px;
}

.label-filter {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
}

li.page-item {
    margin-right: 10px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 2px;
}

.pagina {
    margin-top: 20px;
}

.page-item:first-child .page-link {
    border-radius: 0;
}

.page-item:last-child .page-link {
    border-radius: 0;
}

.page-link {
    position: relative;
    display: block;
    color: #272727;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #DFDFDF;
}

.load-more {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    letter-spacing: 0em;
    text-align: left;
    color: #E81C2E;
    align-items: center;
}

i.fa-solid.fa-circle-plus {
    font-size: 22px;
    margin-right: 14px;
}

.view-a {
    color: #E81C2E;
    text-decoration: underline;
}

.view-a:hover {
    color: #E81C2E;
}

button.hover-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #E81C2E;
    border-radius: 6px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    border: 0;
    padding: 14px 12px;
    transition: 0.4s;
    display: none;
}

.Drivetrain-cart:hover .engle-imge-upper::before {
    content: '';
    background: #05050591;
    height: 100%;
    width: 100%;

    position: absolute;
    left: 0;
}

.Drivetrain-cart:hover .hover-btn {
    /* visibility: visible; */
    display: block;
}

.engle-imge-upper {
    position: relative;
}

.show-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.min-price,
.max-price {
    padding: 15px 31px 15px 14px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
}

.go-btn {
    background: #E81C2E;
    border-radius: 4px;
    padding: 10px 14px;
    border: 0;
    font-family: Roboto;
    font-size: 18px;
    height: 48px;
    font-weight: 800;
    line-height: 21px;
    letter-spacing: 0em;
    color: #FFFFFF;
    text-align: center;
}

.main-filter-resposive {
    display: none;
}

.active-bread a {
    color: #fff;
}

.main-headin {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-outer-filter.open {
    left: 0;
}

button.mobile-filter-btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: right;
    color: #272727;
}

button.mobile-filter-btn svg {
    width: 16px;
    height: auto;
}

.second-bar-p {
    background-color: #222324;
}



.product-name-details .tag_name {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #E81C2E;
}

.product-name-details h2 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 800;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
}

.stock {
    display: flex;
    align-items: center;
}

.stock h3 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 9px !important;
    color: #595959;
}

.stock h4 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 15px !important;
    color: #177200;
}

.stock h5 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 15px !important;
    color: #595959;
    border-left: 1px solid #ccc;
}

.stock h5 span {
    color: #E81C2E;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.product-name-details {
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.product-deastil-preice {
    display: flex;
    align-items: center;
    padding-top: 14px;
    margin: 10px 0 15px;
    flex-wrap: wrap;
}

.product-deastil-preice .price {
    margin-right: 20px;
}

.product-deastil-preice h2 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0em;
    color: #E81C2E;
    margin: 0;
    text-align: left;
}

.product-deastil-preice .price h2 s {
    font-size: 18px;
    margin-left: 10px;
}

.product-deastil-preice .free {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.product-deastil-preice .free .fa {
    font-size: 30px;
    color: #E81C2E;
}

.product-deastil-preice .free .refer {
    font-size: 18px;
    color: #E81C2E;
    font-weight: 700;
    line-height: normal;
}

.product-deastil-preice .free .refer p {
    font-size: 12px;
    margin: 0;
}

.slider-pernt {
    padding-top: 20px;
}

.quantity {
    display: flex;
    align-items: center;
}

.cart button {
    background: #E81C2E;
    border-radius: 4px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    transition: 0.3s;
    text-align: left;
    color: #FFFFFF;
    border: 0;
    padding: 14px 28px;
    border: 2px solid #E81C2E;
}

.cart button:hover {
    background-color: #fff;
    border: 2px solid #E81C2E;
    color: #e81c2e;
}

.cart button svg path {
    transition: 0.5s;
}

.cart button:hover svg path {
    fill: #e81c2e;

}

.Finance button {
    background: transparent;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 700;
    line-height: normal;
    transition: 0.3s;
    margin-left: 15px;
    color: #E81C2E;
}

.Finance button:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.slider__prev {
    position: absolute;
}

.slider__next {
    position: absolute;
}

.slider .swiper-container {
    width: 100%;
    height: 100%;
}

.slider__flex {
    display: flex;

}

.slider__col {
    display: flex;
    flex-direction: column;
    width: 88px;
    margin-right: 4px;
}

.slider__prev,
.slider__next {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slider__next {
    position: absolute;
    bottom: -17px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    padding: 15px 10px 10px 12px;
    background: red;
    border-radius: 50%;
    width: 34px !important;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    /* transform: matrix(0, 1, 1, 0, 0, 0); */
    height: 34px !important;
}

.slider__prev {
    position: absolute;
    top: -17px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    padding: 15px 10px 10px 12px;
    background: red;
    border-radius: 50%;
    width: 34px !important;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    /* transform: matrix(0, 1, 1, 0, 0, 0); */
    height: 34px !important;
}

.slider__prev:focus,
.slider__next:focus {
    outline: none;
}

.slider__thumbs {
    height: 450px;
    position: relative;
    width: 60px;
}

.slider__thumbs .slider__image {
    transition: 0.25s;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
}

.slider__thumbs .slider__image:hover {
    opacity: 1;
    cursor: pointer;
}

.slider__thumbs .swiper-slide-thumb-active .slider__image {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 1;
}

.slider__images {
    height: 400px;
}

.slider__images .slider__image img {
    transition: 3s;
}

/* .slider__images .slider__image:hover img {
    transform: scale(1.1);
} */

.slider__image {
    width: 100%;
    height: 100%;
}

.slider__image img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

section.slider-pernt {
    max-width: 95%;
    margin: auto;
}

.value-button {
    display: inline-block;
    margin: 0px;
    width: 40px;
    height: auto;
    text-align: center;
    vertical-align: middle;
    padding: 11px 0;
    font-size: 11px;
    background: #eeeeee00;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.value-button i {
    color: #757575;
    font-size: 15px;
}

.value-button:hover {
    cursor: pointer;
}

.txt-quainty {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
    margin: 15px 0px;
    text-align: left;
    color: #272727;
}

form #decrease {
    margin-right: -4px;
    border-radius: 8px 0 0 8px;
}

form #increase {
    margin-left: -4px;
    border-radius: 0 8px 8px 0;
}

form #input-wrap {
    margin: 0px;
    padding: 0px;
}

input#number {
    text-align: center;
    margin: 0px;
    width: 50px;
    height: 50px;
    border: 0;
    outline: none;
    font-family: Roboto;
    font-size: 25px;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: 0em;
    color: #272727;
}

.incres {
    margin-right: 15px;
    background: #FFFFFF;
    border: 1.5px solid #ECECEC;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.accordion-item {
    border-top: 1px solid #dddd !important;
    border: none;
}

button.accordion-button {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #A6A6A6;
}

.accordion-body h3 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: justify;
    color: #595959;
}

.realted-link li a {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
    text-decoration: underline;
}

.realted-link li {
    list-style: none;
}

.realted-link li::before {
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.Simila-txt {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
}

.smilar-product {
    border: 1px solid #cccccc54;
}

.also-bought {
    padding-top: 70px;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.accordine-s {
    padding-top: 43px;
}

.accordion-button:not(.collapsed) {
    color: #A6A6A6;
    background-color: #ffffff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
}

.accordion-button:focus {
    z-index: 3;
    border-color: #c5c9ce00;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(221, 221, 221, 0);
}

.slider__thumbs .swiper-slide.swiper-slide-visible {
    height: 60px !important;
    border: 1px solid gray;
    padding: 2px;
    border-radius: 4px;

}

.slider__thumbs .swiper-slide.swiper-slide-visible:hover {
    border-radius: 1px solid red;

}

@media (max-width: 767.98px) {
    .slider__flex {
        flex-direction: column-reverse;
    }

    /* .main-inner-banner {
        width: 550px;
    } */
    .main-header .secrch-bar .search_box_main form.form-search input {
        margin-left: 13px;
    }

    .slider__col {
        flex-direction: row;
        align-items: center;
        margin-right: 0;
        margin-top: 20px;
        width: 100%;
    }

    .slider__images {
        width: 100%;
    }

    .slider__thumbs {
        height: 65px;
        width: 100%;
        margin: 0 0px;
    }

    .slider__prev,
    .slider__next {
        height: auto;
        width: 32px;
    }

    .mian-vernty-img img {
        width: 100%;
        padding: 0 !important;
    }

    .warranty-instruction {
        padding: 10px 0px !important;
    }

    .mian-term-bar {
        padding-top: 0 !important;
    }
}

@media only screen and (max-width: 812px) and (min-width: 375px) {
    .crose {
        margin-right: 5px;
    }

    a.searchIconClose {
        margin-right: 4px;
        margin-top: -5px;
    }

    .main-header .secrch-bar .search_box_main form.form-search input {
        margin-left: 13px;
    }

    .search_box_main .result-div {
        overflow-x: hidden !important;
    }
}


.cart-box .form-control {
    margin-bottom: 10px;
    margin-top: 5px;
}

select.selected-iteam {
    height: 43px;
    width: 100%;
    background: #F2F2F2;
    border-radius: 4px;
    font-family: Roboto;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 10px;
    letter-spacing: 0em;
    text-align: left;
    border: 0;
    color: #595959;
}

.warnnty-text h2 {
    font-family: Roboto;
    font-size: 35px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
    padding-bottom: 22px;
}

.warranty-instruction {
    padding: 80px 0px;
    border-bottom: 1px solid #dddddd;
}

.check-text {
    display: flex;
    align-items: self-start;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #595959;
    margin-bottom: 20px;
}

.contact-anker {
    color: #E81C2E;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    letter-spacing: 0em;
    text-align: left;

}

.contact-anker:hover {
    color: #E81C2E;
}

.siderr-bar h5 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #272727;
}

.siderr-bar::after {
    position: absolute;
    content: '';
    border-left: 5px solid #EAEAEA;
    height: 100%;
    top: 0;
    left: 9px;
    z-index: -1;
    border-radius: 2.5px;
}

.siderr-bar p {
    padding-left: 44px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #595959;
}

.mian-term-bar {
    padding: 80px 0;
    border-bottom: 1px solid #dddddd;
}

.shipingg:not(:first-child) {
    margin-top: 40px;
}

.shipingg h3 {
    font-family: Roboto;
    font-size: 35px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    /* text-align: center; */
    padding-left: 44px;
    color: #E81C2E;
}

.my-account {
    display: grid;
}

.my-account span {
    margin-bottom: 5px;
}

.toggle-password {
    float: right;
    cursor: pointer;
    margin-right: 37px;
    margin-top: -25px;
    padding: 2px;
    background: white;
}

.cart-div {
    padding: 5px 0;
}

.lds-ring {
    display: inline-block;
    position: relative;

}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -10px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
    left: -20px;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.enquiry-form-class .form-label {
    margin: 0 !important;
}

form.enquiry-form-class .row {
    margin-bottom: 11px;
}

.add_vehicle_mobile .fa-times {
    position: absolute;
    right: 0;
    top: 0;
}

.selling-product .product-inner-image,
.card-tyr .tyre-imge-upper {
    cursor: pointer;
}

.error-screen {
    text-align: center;
    margin: 84px 0;
}

#webpack-dev-server-client-overlay-div {
    display: none !important;
}

.cart-main .cart-body .total-main .policy_terms span {
    vertical-align: text-top;
}

.cart-main .cart-body .total-main .policy_terms span:first-child {
    padding-right: 5px;
    font-size: 17px;
}

.policy_terms input {
    width: 20px;
    height: 20px;
}

.finance-section {
    padding: 50px 0;
}

.container {
    text-align: left;
    position: relative;
}

.finance-section .container {
    width: 80%;
    max-width: 1080px;
    margin: auto;
    position: relative;
}

.finance-section .content {
    text-align: center;
}

.finance-section .content h1 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
    line-height: normal;
    font-weight: 700;
}

.finance-section .content p {
    font-size: 22px;
    margin-bottom: 30px;
    font-weight: 700;
    color: #333;
}

.container a.page_btn_cmmncls {
    text-align: center;
}

.page_btn_cmmncls {
    color: #fff !important;
    letter-spacing: 0;
    background-color: #E81C2E;
    padding: .3em 1em;
    position: relative;
    border: 2px solid #E81C2E;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.7em !important;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    transition: all .2s;
    display: inline-block;
}

.finance-logo {
    text-align: center;
    padding: 50px 0 0;
}

.finance-logo h2 {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: normal;
    color: #333;
    font-weight: 700;
    text-align: center;
}

.finance-logo .logos {
    flex-wrap: wrap;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 5px solid #d9aa00;
}

.shop-section {
    text-align: center;
    padding: 50px 0;
}

.shop-section .shop-with {
    padding: 50px 0;
    text-align: center;
}

.shop-section h2 {
    font-size: 30px;
    line-height: normal;
    color: #333;
    font-weight: 700;
    text-transform: uppercase;
}

.shop-section .step-boxes {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
}

.shop-section .step-boxes .box:first-child {
    border-right: 0;
}

.shop-section .step-boxes .box {
    max-width: 33%;
    width: 100%;
    border: 1px solid #000;
}

.shop-section .step-boxes .box .box-content {
    padding: 30px 20px;
}

.shop-section .step-boxes .box .box-content h3 {
    font-size: 24px;
    line-height: 28px;
    color: #2c2c2c;
    margin-bottom: 15px;
}

.shop-section .step-boxes .box .box-content p {
    font-size: 16px;
    color: #2c2c2c;
    line-height: 26px;
    margin-bottom: 12px;
}

.finance-logo .logos a img {
    max-width: 25%;
    width: 100%;
    height: auto;
}

.shop-section .step-boxes .box .box-content a {
    background: #E81C2E;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
}

.tiresCard {
    width: 60px;
    font-size: 9px;
    text-align: center;
}

.fottor-list p a:hover {
    color: #e81c2e;
    text-decoration: underline;
}

.custome_products .add_to_cart {
    cursor: pointer;
    font-size: 14px;
}

.custome_products .Drivetrain-cart {
    padding-bottom: 0px;
}

.custome_products .Drivetrain-cart .Drivetrain-cart .cart-body .price-cart {
    position: relative !important;
    margin: 5px;

}

.tiresCards .wheelsPage .price-cart img {
    max-width: 70px;
    max-height: 70px !important;
}

.tiresCards .Drivetrain-cart .cart-btn img {
    width: 100%;
    max-width: 70px !important;

}

.search_box_main .result-div {
    overflow-x: hidden !important;
}

.Toastify__close-button {
    margin-top: 10px;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: green;
}

.react-confirm-alert {
    font-family: Roboto;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #000;
    position: absolute;
    top: 60px;
    ;
}

.react-confirm-alert .custom-ui button {
    outline: none;
    background: #E81C2E;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

.search_banners .banner_box_inside {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: calc(50% - 7.5px);
    column-gap: 10px;
    border: none;
    margin-top: 0;
    border-radius: 6px;
    justify-content: start;
    padding: 0;
    cursor: pointer;
    transition: all .3s;
}

.wheelSearchInnerDiv .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
    position: absolute;
    top: 30%;
    left: 40%;
}

.wheelSerachButton {
    position: relative;
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: none !important;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.input-group-append {
    margin-top: 34px;
    margin-right: -26px;
    padding-left: 11px;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.row.forgotPassword {
    width: 40%;
    margin: auto;
}

.row.forgotPassword h2 {
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}

.row.forgotPassword label {
    color: #595959;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 10px;
}

.row.forgotPassword input.form-control {
    margin-bottom: 18px;
}

.row.forgotPassword button.btn.btn-danger.btn-sm {
    border-radius: 6px;
    background: #E81C2E;
    color: #FFF;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* padding: 20px; */
    padding: 14px 8px 14px 7px;
    width: 100%;
    border: 1px solid #E81C2E;
}

.row.resetPassword {
    width: 40%;
    margin: auto;
}

.row.resetPassword h2 {
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}

.row.resetPassword label {
    color: #595959;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 10px;
}

.row.resetPassword input.form-control {
    margin-bottom: 18px;
}

.row.resetPassword button.btn.btn-danger.btn-sm {
    border-radius: 6px;
    background: #E81C2E;
    color: #FFF;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* padding: 20px; */
    padding: 14px 8px 14px 7px;
    width: 100%;
    border: 1px solid #E81C2E;
}


/* date 21-09-2023 */
.mainDivForgetPassord .errorMessageDiv,
.resetPasswordMainDiv .errorMessageDiv {
    background: #E81C2E;
    color: white;
    text-align: center;
    padding: 10px;
    /* position: absolute; */
    /* top: 0; */
    margin-bottom: 15px;
}

.mainDivForgetPassord .suessMessageDiv,
.resetPasswordMainDiv .suessMessageDiv {
    background: green;
    color: white;
    text-align: center;
    padding: 10px;
    /* position: absolute; */
    /* top: 0; */
    margin-bottom: 15px;
}

.forgetPasswordLink {
    font-size: 12px;
    text-align: start;
}

.forgetPasswordLink a {
    color: red;
}

.forgetPasswordLink a:hover {
    text-decoration: underline;
}

.css-187mznn-MuiSlider-root {
    border-radius: 12px;
    box-sizing: content-box;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    color: #E81C2E !important;
    -webkit-tap-highlight-color: transparent;
    height: 4px;
    width: 100%;
    padding: 13px 0;
}

.price-cart .affirm-as-low-as {
    font-size: 11px;
    font-weight: 700;
}

.wheelsPage.leftSide .cart-body .price-cart p {
    margin-top: 6px;
    color: #212529;
    text-overflow: ellipsis;
    white-space: none !important;

    padding-bottom: 1px;
    font-size: 12px;
}


.financeButton {
    background-image: url("http://localhost:3000/icons/snapfinancelogo.png");
}


/* 03-10-2023 */
.all_category .card .row {
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    box-shadow: 0 3px 15px #00000029;
    /* position: relative; */
    /* display: block; */
    /* height: 80px; */
    background: #fff;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.all_category .card .row:hover {
    border-bottom: 5px solid #E81C2E;
}


.all_category .card .row:hover img {
    transform: scale(1.1);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.all_category .card-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    margin: 0;
    padding: 0;
    max-width: 100%;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    line-height: 1;
}

.top_sell_cat_text .shimmer-button,
.price_tag .shimmer-button {
    margin-bottom: 5px !important;
}

.css-b62m3t-container {
    text-align: left !important;
}

#fitment-collapse-text-fitment .form-control:focus {
    box-shadow: none !important;
}

.freeshippingDiv svg {

    width: 30px;
    height: 30px;

}

.freeshippingDiv span {

    font-size: 18px;
    font-weight: 600;
    margin-right: 10px;

}

.tire_logo_cart_div {
    margin-top: 25px
}

.tire_logo_cart_div .cart-div {
    margin: 0;
    padding: 0;

}

.brands_images .brand_name {
    font-size: 25px;
    font-weight: 600;
}


.suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
    border-radius: 6px;
}

.suggestion-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: black;
    /* height: calc(100vh - 210px); */
    margin-top: 6px;
    margin-bottom: 10px;
    padding-bottom: 0 !important;
    padding-right: 5px;
    overflow: auto;
    margin-left: 1%
}

.suggestion-details {
    flex-grow: 1;
    display: flex;
    line-height: normal;

}


.loader1-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding-left: 6px;

}

.loader1 {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #e81c2e;
    ;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.main-header1 {
    background-color: white;
    color: #fff;
    padding: 20px 0;
    border-bottom: 1px solid #dddddd;
}

.container1 {
    width: 80%;
    margin: 0 auto;
}

.mian-header1 {
    display: flex;
    align-items: center;

}

.main-header-searchbar1 {
    flex: 1;
}

.search-container1 {
    position: relative;
    border: 1px solid rgb(175 154 154);
    border-radius: 10px;
}

.form-search1 {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 6px;

}

.form-search1 input {
    background: #FFF;
    border: none;
    box-shadow: none;
    color: black;
    font-size: 16px;
    font-family: Roboto;
    max-width: 100%;
    width: 100%;
    height: 57px;
    padding-left: 6px;

}

.form-search1 button {
    background-color: #e81c2e;
    ;
    color: white;
    padding: 13px 30px;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    pointer-events: none;
}

.search-bar1 {
    width: 50%;
}

.suggestion-details .image {
    max-width: 35px;
    min-width: 35px;
    min-height: 35px;
    max-height: 35px;
    object-fit: contain;
    margin-top: 7px;

}

.suggestion-details .content {
    margin-left: 2%;


}

.suggestion-details p {
    margin: 0;
    font-size: 15px;

}

.suggestion-details .sku {

    font-size: 10px;

}


.form-search1 input:focus:empty {
    border: none;
    outline: none;
}


/* / width / */
::-webkit-scrollbar {
    width: 5px;
}

/* / Track / */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    overflow: hidden;
    border-radius: 5px;
}

/* / Handle / */
::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
}

/* / Handle on hover / */
::-webkit-scrollbar-thumb:hover {
    background: #adadad;
}

.wheel-fitment-section .table-wrapper {
    max-height: 300px;
    /* Set the desired height */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.wheel-fitment-section .table-body {
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.wheel-fitment-section .table th {
    position: sticky;
    top: 0;
    background-color: #fff;
    /* Ensure the background is white to hide other rows */
    border: 1px solid #ddd;
}

.wheel-fitment-section .table {
    border-collapse: collapse;
    /* Collapse borders to ensure consistency */
    width: 100%;
    /* Ensure the table takes up the full width of its container */
    border: 1px solid #ddd;
    /* Add border to the table */
}

.filter-button {
    padding: 15px 0;
}

.filter-button button {
    width: 100%;
}

.priceandinventory {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.selling-product .product-data {
    margin-bottom: 10px;
}
.selling-product .product-data .product-inner-image{
    border: 1px solid #d9e0d9;
    border-radius: 5px;   
}
.selling-product .product-data span.product-cate {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 5px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 0.5rem;
    display: block;
    height: 48px;
    overflow: hidden;
    font-weight: 600;
    line-height: 14px;
}

.selling-product .product-data .priceandinventory .price {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #e81c2e;
}
.selling-product .product-data .priceandinventory .stock {
    font-family: Roboto;
   
    font-weight: 800;
   
}